export function init() {
    'use strict';

    let headerSearchButton = document.getElementById('js-toggle-header-search'),
        headerSearchPanel = document.getElementById(headerSearchButton.getAttribute('aria-controls')),
        headerSearchPanelButton = headerSearchPanel.getElementsByTagName('button')[0],
        headerContainer = document.querySelector('.header-container'),
        overlay = document.getElementById('js-overlay');

    function toggleSearch() {

        if (headerSearchButton.getAttribute('aria-expanded') === 'true') {
            headerSearchButton.setAttribute('aria-expanded', false);
            headerSearchPanel.setAttribute('aria-hidden', true);
            headerSearchButton.getElementsByTagName('span')[0].innerHTML = 'Søg';
            overlay.classList.remove('is-visible');
        } else {
            headerSearchButton.setAttribute('aria-expanded', true);
            headerSearchPanel.setAttribute('aria-hidden', false);
            headerSearchButton.getElementsByTagName('span')[0].innerHTML = 'Luk';

            headerSearchPanel.addEventListener('transitionend', () => {
                headerSearchPanel.getElementsByTagName('input')[0].focus();
            });

            overlay.style.top = headerContainer.offsetHeight + 1 + 'px';
            overlay.classList.add('is-visible');

        }
    }

    function keys(e) {
        if (headerSearchButton.getAttribute('aria-expanded') === 'true') {
            if (27 === e.keyCode) {
                // esc key
                toggleSearch();
            }
        }
    };

    function closeWhenTabbing(e) {
        if (9 === e.keyCode) {
            e.preventDefault();
            // tab key
            toggleSearch();
            headerSearchButton.focus();
        }
    }

    function closeOpenSearch(e) {
        if (headerSearchButton.getAttribute('aria-expanded') === 'true'
            && !e.target.closest('#' + headerSearchPanel.id)
            && !e.target.closest('#' + headerSearchButton.id)) {
            toggleSearch();
        }
    }

    document.addEventListener('DOMContentLoaded', () => {
        headerSearchButton.addEventListener('click', toggleSearch);
        headerSearchPanelButton.addEventListener('keydown', closeWhenTabbing);
        document.addEventListener('click', closeOpenSearch);
        document.addEventListener('keyup', keys);
    });
}

export default { init };