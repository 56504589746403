// missing forEach on NodeList for IE11
if(window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

// closest polyfill for IE11
if(!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

if(!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;

        do {
            if (Element.prototype.matches.call(el, s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

import whatInput from 'what-input';
import megaNav from './components/mega-nav.js';
import headerSearch from './components/header-search.js';
import mobileNav from './components/mobile-nav.js';

(() => {
    'use strict';

    megaNav.init();
    headerSearch.init();
    mobileNav.init();
})();