import trapFocus from '../components/trap-focus.js';

export function init() {
  'use strict';

  let $openButton = $('#js-open-mobile-nav'),
    $closeButton = $('#js-close-mobile-nav'),
    $overlay = $('#js-overlay'),
    $mobilePanel = $('#' + $openButton.attr('aria-controls')),
    $backButton = $('#js-mobile-nav-back'),
    $menuWrapper = $('.mobile-nav'),
    $menu = $('#js-mobile-nav'),
    isLoaded = 0,
    $loadingSpinner = $('#js-mobile-nav-spinner'),
    $mobileSubNav = $('.mobile-sub-nav');

  // Functions
  function getNavItems() {
    if (isLoaded === 0) {
      var feedUrl = $menu.data('nav-items-url');

      $.ajax({
        type: 'GET',
        url: feedUrl,
        dataType: 'json',
        success: (data) => {
          renderNavigation(data);
        },
        error: (error) => {
          console.log(error.statusText);
        },
      });
    }
  }

  function setBackButtons(isFrontPage) {
    if (isFrontPage) {
      $backButton.removeClass('is-active').attr('aria-hidden', 'true');
    } else {
      $backButton.addClass('is-active').attr('aria-hidden', 'false');
    }
  }

  function drillDownNavigation() {
    let currentNodeId = $menu.data('node-id'),
      currentNode = $menu.find(`[data-id='${currentNodeId}']`);

    if (isFrontPage()) {
      $menuWrapper.css('height', $menuWrapper.height() + 1);
    } else {
      currentNode
        .addClass('current')
        .find('ul')
        .first()
        .addClass('is-active');
      currentNode.parents('ul').addClass('is-active');
      $menuWrapper.css(
        'height',
        Math.ceil(
          currentNode
            .find('ul')
            .first()
            .height() + 1
        )
      );
    }

    setBackButtons(isFrontPage());
    $mobileSubNav.removeClass('is-hidden');

    var forwardButtons = $menu.find('.forward');

    forwardButtons.on('click', (e) => {
      var $this = $(e.target);

      e.preventDefault();
      e.stopPropagation();

      var d = $this
        .closest('li')
        .find('ul')
        .first();

      $menuWrapper.css('height', d.outerHeight());
      d.addClass('is-active').attr('aria-hidden', 'false');

      if ($mobilePanel.scrollTop() > 0) {
        setTimeout(() => {
          $mobilePanel.animate({ scrollTop: 0 }, '500');
        }, 300);
      }

      setBackButtons(false);
    });
  }

  function isFrontPage() {
    var activeUl = $menu.find('.is-active').last();
    return activeUl.length === 0;
  }

  function renderItems(nodes, navParentName, navClassName) {
    navClassName = typeof navClassName === 'undefined' ? '' : navClassName;
    var markup = `<ul class="${navClassName}" role="group" aria-hidden="true">`;

    markup += `<li class="back"><span>${navParentName}</span></li>`;

    for (let key in nodes) {
      var item = nodes[key];
      var hasChildren = item.items.length > 0;

      markup += hasChildren
        ? `<li class="has-children" data-id="${item.id}" role="treeitem">`
        : `<li data-id="${item.id}" role="treeitem">`;
      markup += `<a href="${item.url}" role="menuitem">${item.name}</a>`;

      if (hasChildren) {
        markup += renderItems(item.items, item.name);
      }

      markup += '</li>';
    }

    markup += '</li></ul>';

    return markup;
  }

  function toggleMobilePanel() {
    if ($mobilePanel.attr('aria-hidden') === 'true') {
      $('html').addClass('no-scroll');

      if ($('#js-toggle-header-search').attr('aria-expanded') === 'true') {
        setTimeout(() => {
          $overlay.removeAttr('style').addClass('is-visible');
        }, 250);
      } else {
        $overlay.removeAttr('style').addClass('is-visible');
      }

      $mobilePanel.attr('aria-hidden', false);
      $openButton.attr('aria-expanded', true);

      $mobilePanel.one('transitionend', () => {
        $mobilePanel
          .find('button:not([aria-hidden="true"])', 'a')
          .first()
          .focus();
      });

      trapFocus.trap(
        document.getElementById($openButton.attr('aria-controls'))
      );
    } else {
      $mobilePanel.attr('aria-hidden', true);
      $openButton.attr('aria-expanded', false);
      $('html').removeClass('no-scroll');

      $mobilePanel.one('transitionend', () => {
        $openButton.focus();
        $overlay.removeClass('is-visible');
      });
    }
  }

  function keys(e) {
    if ($mobilePanel.attr('aria-hidden') === 'false') {
      if (27 === e.keyCode) {
        // esc key
        toggleMobilePanel();
      }
    }
  }

  function renderNavigation(nodes) {
    isLoaded = 1;

    var markup = '';

    for (let key in nodes.mainNavigation) {
      var item = nodes.mainNavigation[key],
        hasChildren = item.items != null && item.items.length;

      markup += hasChildren
        ? `<li class="has-children" data-id="${item.id}" role="treeitem">`
        : `<li data-id="${item.id}" role="treeitem">`;
      markup += `<a href="${item.url}" role="menuitem">${item.name}</a>`;

      if (hasChildren) {
        markup += renderItems(item.items, item.name, 'mobile-nav-submenu');
      }
      markup += '</li>';
    }

    let promise = new Promise((resolve) => {
      $menu
        .prepend(markup)
        .find('.has-children > a, .has-children > button')
        .append(
          '<button class="forward"><span class="sr-only">Udvid menupunkt</span></button>'
        );

      resolve();
    });

    promise.then(
      () => {
        $loadingSpinner.addClass('hidden');
        drillDownNavigation();
      },
      (error) => {
        console.log('Rejected: ' + error);
      }
    );
  }

  // Event handlers

  $overlay.on('click', (e) => {
    if ($mobilePanel.attr('aria-hidden') === 'false') {
      toggleMobilePanel();
    }
  });

  $openButton.on('click', (e) => {
    e.preventDefault();
    $openButton.attr('aria-expanded', true);
    toggleMobilePanel();
    getNavItems();
  });

  $closeButton.on('click', (e) => {
    e.preventDefault();
    toggleMobilePanel();
  });

  $backButton.on('click', (e) => {
    e.preventDefault();

    var activeUl = $menu.find('.is-active').last(),
      parentUl = activeUl.parent().closest('ul');

    $menuWrapper.css('height', parentUl.outerHeight() + 1);
    activeUl.removeClass('is-active').attr('aria-hidden', 'true');

    if (parentUl.is('#js-mobile-nav')) {
      setBackButtons(true);
    }
  });

  document.addEventListener('keyup', keys);
}

export default { init };
