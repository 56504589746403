export function init() {
    'use strict';

    let toggleLinks = document.querySelectorAll('.toggle-nav'),
        subNavLinks = document.querySelectorAll('.mega-nav-panel a'),
        container,
        currentMenuItem,
        len = toggleLinks.length - 1;

    function toggleOnMenuClick(e) {
        const button = e.currentTarget;

        // close open menu if there is one
        if (currentMenuItem && button !== currentMenuItem) {
            toggleSubmenu(currentMenuItem);
        }

        toggleSubmenu(button);
    };

    function closeOpenMenu(e) {
        if (currentMenuItem && !e.target.closest('#' + container.id) && e.target !== currentMenuItem) {
            toggleSubmenu(currentMenuItem);
        }
    };

    function toggleSubmenu(button) {

        const submenu = document.getElementById(button.getAttribute('aria-controls'));

        if ('true' === button.getAttribute('aria-expanded')) {
            button.setAttribute('aria-expanded', false);
            submenu.setAttribute('aria-hidden', true);
            currentMenuItem = false;
            container = false;
            // button.focus(); 
        } else {
            button.setAttribute('aria-expanded', true);
            submenu.setAttribute('aria-hidden', false);
            currentMenuItem = button;
            container = submenu;

            // Focus on first link in subnav when user click on section button
            submenu.addEventListener('transitionend', () => {
                submenu.querySelectorAll('a')[0].focus();
              });
        }
    };

    function toggleLinksKeys(e) {
        //let children = e.target.closest('ul').children;
        //let index = Array.from(children).indexOf(e.target.parentNode);

        if (27 === e.keyCode) {
            // esc key
            if ('true' === e.target.getAttribute('aria-expanded')) {
                toggleSubmenu(currentMenuItem);
            }

        }
        else if (38 === e.keyCode) {
            // arrow up
            e.preventDefault();

            if (currentMenuItem != null) {
                toggleSubmenu(currentMenuItem);
            }
        }
        else if (40 === e.keyCode) {
            // arrow down
            e.preventDefault();

            if (currentMenuItem && e.target !== currentMenuItem) {
                toggleSubmenu(currentMenuItem);
                toggleSubmenu(e.target);
            } else if (e.target === currentMenuItem) {
                document.getElementById(e.target.getAttribute('aria-controls')).querySelectorAll('a')[0].focus();
            } else {
                toggleSubmenu(e.target);
            }
        }

    };

    function toggleSubNavKeys(e) {
       if (27 === e.keyCode) {
            // esc key
            toggleSubmenu(currentMenuItem);
        }
    };

    document.addEventListener('DOMContentLoaded', () => {
        document.addEventListener('click', closeOpenMenu);

        subNavLinks.forEach(link => {
            link.addEventListener('keyup', toggleSubNavKeys);
        });

        toggleLinks.forEach(menu => {
            menu.addEventListener('click', toggleOnMenuClick);
            menu.addEventListener('keyup', toggleLinksKeys);
        });

    });
}

export default { init };